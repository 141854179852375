<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="container my-5">
            <div class="text-center mb-4">
                <h1 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>PRE-KINDER AND KINDERGARTEN CURRICULUM</strong></h1>
            </div>
            <div class="text-center mb-4" style="margin-top: 150px;">
                <h3 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>About our Program</strong></h3>
            </div>
            <div class="row" style="margin-top: 100px;">
                <div class="col-md-6">
                    <h4 style="color: #012376; font-family: Arial; font-size: 1.25rem;"><strong>Pre-Kinder</strong></h4>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <h4 style="color: #012376; font-family: Arial; font-size: 1.25rem;"><strong>Kinder</strong></h4>
                    <p style="color: #012376; font-family: Arial; font-size: 1.05rem;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
                <div class="col-md-6 position-relative">
                    <div class="blue-shape"></div>
                    <div class="image-container">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/photo_2024-05-20_18-05-35.jpg'" alt="Image 1" class="image image1">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/photo_2024-05-20_18-04-38.jpg'" alt="Image 2" class="image image2">
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top: 250px;">
            <center>
                <h1 style="color: #012376; font-family: Arial; font-size: 2rem;"><strong>OUR FACILITIES</strong></h1>
                <br><br>
                <el-carousel type="card" height="500px" :autoplay="false">
                    <el-carousel-item v-for="res in resources" :key="item">
                        <img :src="res" style="height: 500px; width: 500px;">
                    </el-carousel-item>
                </el-carousel>
            </center>
        </div>

        <div style="margin-top: 150px;">
            &nbsp;
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .blue-shape {
        position: absolute;
        width: 350px;
        height: 350px;
        background-color: #002d72;
        transform: rotate(45deg);
        top: -10%;
        right: 10%;
        z-index: 0;
        left: 50%;
    }

    .image-container {
        position: relative;
        width: 100%;
        padding-top: 50%; /* Maintain aspect ratio */
    }

    .image {
        position: absolute;
        border: 5px solid white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        transition: transform 0.3s ease;
    }

    .image1 {
        top: -10%;
        left: 50%;
        width: 60%;
        transform: rotate(-5deg);
        z-index: 2;
    }

    .image2 {
        top: 70%;
        left: 25%;
        width: 60%;
        transform: rotate(5deg);
        z-index: 3;
    }

    .image:hover {
        transform: scale(1.05);
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Cover the container */
        border: 2px solid white;
        border-radius: 5px;
    }


</style>
<script>
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteHeader from "../../../components/general/SiteHeader";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp
            }
        },
        mounted() {

        },
        created() {

        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }
</script>