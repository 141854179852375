<template>
    <div>
        <div class="footer-background">
            <footer class="footer overlay" :style="{ backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative' }">
                <div class="container text-white py-4">
                    <div class="row">
                        <div class="col-md-4">
                            <img style="width: 300px; height: 300px;" :src="`${env_wp.APP_URL}//public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_logo.png`" alt="SSCMANILA LOGO">
                        </div>
                        <div class="col-md-4 justify-content-center">
                            <div class="pull-left" style="padding-top: 50px;">
                                <p style="font-weight: bolder; font-family: Arial;">
                                    <a class="non-a-tag" href="https://ssc.edu.ph/wp-content/uploads/2023/10/45-PLANNING-AND-QUALITY-ASSURANCE-OFFICE-Data-Privacy-Notice-for-Employees-and-Students.pdf">SSC DATA PRIVACY NOTICE FOR EMPLOYEES AND STUDENTS</a><br><br>
                                    <a class="non-a-tag" href="https://www.facebook.com/SSCGradeSchool">The Grade School Unit’s Facebook Page</a><br><br>
                                    <a class="non-a-tag" href="https://sites.google.com/ssc.edu.ph/highschool/">The High School Unit’s Google Site</a><br><br>
                                    <a class="non-a-tag" href="https://sites.google.com/ssc.edu.ph/college-unit/home">The College Unit’s Google Site</a><br><br>
                                    <a class="non-a-tag" href="https://sites.google.com/view/sscshlrm-edu/home">School of Hotel, Leisure & Restaurant Management</a><br><br>
                                    <a class="non-a-tag" href="https://www.facebook.com/StSchoManilaCCED">Center for Continuing Education & Development</a><br><br>
                                    <a class="non-a-tag" href="https://cre.ssc.edu.ph">Center for Research and Evaluation</a>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4 justify-content-center">
                            <div class="pull-left" style="padding-top: 50px; font-weight: bolder; font-family: Arial;">
                                ADDRESS:
                                2560 Leon Guinto Street Singalong, Malate,
                                Manila Philippines
                                <br>
                                <br>
                                PHONE:
                                632-8567-7686 | 632-8559-7593
                                <br>
                                <br>
                                EMAIL ADDRESS:
                                marketing@ssc.edu.ph | sscadmit@ssc.edu.ph
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</template>
<style scoped>
    .footer-background {
        position: relative;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(1, 35, 118, 0.90);
        z-index: 1;
        border-radius: 0;
    }
    .footer {
        border-radius: 0; /* Remove curved corners */
    }
    .ssamanila_text_btn{
        color: #012277;
        font-weight: bold;
        font-size: 1.05rem;
    }
    .ssamanila_text_nav{
        color: #ffffff;
        font-weight: bold;
        font-size: 1.15rem;
    }
    .ssamanila_text_nav2{
        color: #012277;
        font-weight: bold;
        font-size: 1.05rem;
    }
    a.non-a-tag {
        display: inline;
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        outline: none;
    }
    a.non-a-tag:hover,
    a.non-a-tag:active,
    a.non-a-tag:focus {
        color: #ffffff;
        text-decoration: none;
    }

</style>
<script>
    export default {
        props: {
            title: String,
        },
        data () {
            return {
                nav_color: '#F8FAFC',
                nav_txt_color: '',
                defaultActiveIndex: '0',
                notifications: [],
                folder_name: '',
                nav_menus_pages: [],
                picture: '',
                env_wp: this.$root.env_wp.env_wp
            }
        },
        mounted() {
            this.folder_name = this.$root.folder_name;
            // this.getNavMenusPagesAndSetup();
        },
        created() {
            this.$root.$refs.NavBar = this;
        },
        methods: {
            getNavMenusPagesAndSetup(){
                axios.get(this.folder_name+ '/cms/get_nav_menus_pages')
                    .then(res => {
                        this.nav_menus_pages = res.data.menus_pages;
                        this.nav_color = res.data.setup.navbar_color;
                        this.nav_txt_color = res.data.setup.navbar_text_color;
                        this.picture = res.data.picture.full_path;
                    })
            },
            navigate(URL) {
                if (URL == 'mark_all_as_read') this.notifMarkAsRead()
                else if (!URL) return
                else if (URL != 'mark_all_as_read') window.location = URL
            },
            backHome(){
                window.location.href = this.folder_name + '/home';
            },
            cms(){
                window.location.href = this.folder_name + '/cms';
            },
            gotoLink(link){
                window.open(link, '_blank');
            },
        }
    }
</script>