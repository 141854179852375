<template>
    <div>
        <SiteHeader style="width: 100%;"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <div style="margin-top: 50px;">
            <center><h1 class="ssa-text-color" style="font-family: Arial; font-size: 3rem;"><strong>INSTITUTIONAL OFFICE</strong></h1></center>
        </div>
        <div style="margin-top: 50px;">
            <div class="row" style="margin-left: 15px;">
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="1" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ARCHIVES OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                <span class="ssa-text-color" style="font-family: Arial; font-size: 1.25rem;"><strong>Our Mission and Vision</strong></span>
                                <hr>
                                <span class="ssa-text-color" style="font-family: Arial;">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                    do eiusmod tempor incididunt ut labore et dolore magna
                                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                    Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                    occaecat cupidatat non proident, sunt in culpa qui officia
                                    deserunt mollit anim id est laborum.
                                </span>
                                <div class="row" style="margin-top: 20px;">
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.10rem;"><strong>Key Functions</strong></span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">1. Function 1</span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">2. Function 2</span>
                                        <br>
                                        <span class="ssa-text-color" style="font-family: Arial;">3. Function 3</span>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="ssa-text-color" style="font-family: Arial; font-size: 1.110rem;"><strong>Contact Us</strong></span>
                                        <br>
                                        <i class="fa fa-envelope"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>archives@ssc.edu.ph</strong></span>
                                        <br>
                                        <i class="fa fa-phone-square"></i>&nbsp;<span class="ssa-text-color" style="font-family: Arial;"><strong>8567 - 7686 (local ####)</strong></span>
                                    </div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="3" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ADMINISTRATIVE AND GENERAL SERVICES</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="5" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;ADMISSIONS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="7" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;BUSINESS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="9" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;CAREER AND PLACEMENT OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="11" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;FOOD SERVICES AND BOOKSTORE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="13" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;GUIDANCE AND COUNSELING OFFICES</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="15" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;HUMAN RESOURCE & DEVELOPMENT OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="17" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INFORMATION & COMMUNICATION TECHNOLOGY OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="col-md-6">
                    <el-collapse>
                        <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;MARKETING AND COMMUNICATIONS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INST’L PLANNING OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INST’L STATISTICS & EVALUATION CENTER</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;INST’L QUALITY ASSURANCE OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="10" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;MARKETING AND COMMUNICATIONS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="12" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;MEDICAL & DENTAL HEALTH SERVICES</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="14" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;REGISTRAR’S OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="16" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;SAFETY AND SECURITY OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="18" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;STUDENT AFFAIRS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <div style="margin-bottom: 15px;"></div>
                    <el-collapse>
                        <el-collapse-item name="19" style="background-color: #012376; text-color: #ffffff">
                            <template slot="title">
                                <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;SCHOLARSHIPS AND GRANTS OFFICE</strong>
                            </template>
                            <div>&nbsp;</div>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse
                                cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa qui officia
                                deserunt mollit anim id est laborum.
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        <div style="margin-top: 75px;">
            &nbsp;
        </div>
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
    </div>
</template>
<style>
    .el-collapse-item__header{
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        background-color: #012376;
        color: #ffffff;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        font-size: 13px;
        font-weight: 500;
        transition: border-bottom-color .3s;
        outline: 0;
    }
    .el-collapse-item__content {
        padding-bottom: 25px;
        font-size: 13px;
        color: #303133;
        line-height: 1.769230769230769;
        margin-left: 25px;
    }
</style>
<script>
    import SiteHeader from "../../../components/general/SiteHeader";
    import NavbarStatic from "../../../components/general/NavbarStatic";
    import SiteFooter from "../../../components/general/SiteFooter";
    export default {
        props: {

        },
        components: { SiteHeader,NavbarStatic,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp
            }
        },
        mounted() {

        },
        created() {
            this.addSsaColorClass();
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            addSsaColorClass(){
                $(".el-collapse-item__header").css({
                    "background-color": "#012376",
                    "color": "#ffffff"
                });
            }
        }
    }
</script>