<template>
    <div>
        <!--HEADER NAVBAR START-->
        <SiteHeader style="width: 100%"></SiteHeader>
        <NavbarStatic style="width: 100%"></NavbarStatic>
        <!--HEADER NAVBAR END-->

        <!--CONTENTS START-->
        <div class="college-backdrop">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/college_landing_page_(4 Schools)/backdrops_and_logos_for_college_schools/school_of_arts_and_sciences/School of Arts and Sciences.png'" alt="School of Arts and Science" class="logo">
                    </div>
                    <div class="col-md-6 mt-5">
                        <h1 style="font-family: Arial; font-size: 2rem; color: #ffffff;">
                            SCHOOL OF
                        </h1>
                        <h1 style="font-family: Arial; font-size: 2.75rem; color: #ffffff;">
                            <b>ARTS and SCIENCE</b>
                        </h1>
                    </div>
                </div>
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <h1 class="text-right justify-content-end" style="font-family: Arial; font-size: 3rem; color: #ffffff; margin-bottom: 25px;">About the School of Arts and Science</h1>

                            <h4 class="text-left" style="color: #ffffff; font-size: 2rem;"><b>Vision:</b></h4>
                            <p class="text-left" style="color: #ffffff; font-size: 1.5rem;">We envision our graduates to be academically grounded for professional competence, imbued with Benedictine values and actively engaged in the social, economic and political realities of the local and global community</p>
                            
                            <br>

                            <h4 class="text-left" style="color: #ffffff; font-size: 2rem;">
                                <b>Mission:</b>
                                <br>
                                The School of Arts & Sciences provides a holistic liberal arts core and professional education characterized by: 
                            </h4>
                            <ul class="text-left" style="color: #ffffff; font-size: 1.25rem;">
                                <li>Opportunities for higher cognitive skills</li>
                                <li>Social Responsiveness</li>
                                <li>A passion for art and culture</li>
                                <li>Deep concern for ecology</li>
                                <li>Meaningful and vibrant spirituality</li>
                                <li>Responsible use of technology and other resources</li>
                                <li>Sensitivity to issues of women and the marginalized</li>
                            </ul>
                            <h4 class="text-left" style="color: #ffffff; font-size: 2rem;">
                                so as to contribute to the creation of a just humane society.
                            </h4>

                            <br>

                            <h4 class="text-left" style="color: #ffffff; font-size: 2rem;"><b>Objectives:</b></h4>
                            <p class="text-left" style="color: #ffffff; font-size: 1.5rem;">
                                To provide, through a strong Liberal Arts education, a dynamic academic atmosphere, processes, opportunities and resources that will enable the learners
                                to actively pursue and acquire knowledge and skills and to develop Benedictine values and attitudes
                                that will continuously inspire them to be critically involved, not only in their respective disciplines but also in issues affecting society.
                                The specific goals of the School of Arts and Sciences are to:
                            </p>
                            <ol class="text-left" style="color: #ffffff; font-size: 1.25rem;">
                                <li>Cultivate in the students the art of effective communication</li>
                                <li>Foster creativity and artistic expression</li>
                                <li>Enable the students to use responsibly state-of-the-art technology</li>
                                <li>Develop critical discernment for sound decision-making</li>
                                <li>Enhance research capabilities for scholarly work</li>
                                <li>Promote concern for sustainable environment</li>
                                <li>Acquire intra- and inter-personal skills</li>
                                <li>Nurture Christian and ethical values</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid">
                <hr style="border-top: 2px solid rgba(255, 255, 255, 1);">
                <div class="container-fluid" style="margin-top: 50px;">
                    <div class="row" style="margin-left: 15px;" v-if="isWindowOnDesktop">
                        <div class="col-md-6">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Communication</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Bachelor of Arts in Communication program offers students
                                        the opportunity to develop their media and communication skills required
                                        for careers in radio, television, film, journalism, advertising, public
                                        relations, corporate communications, advocacy and development
                                        communication, new media, and other allied fields. It allows
                                        students to explore any of the following areas as a minor track:
                                        Advocacy Filmmaking, Communication for Development,
                                        Integrated Marketing Communications, and Multimedia Journalism.
                                        <br>
                                        <br>
                                        Anchored on Filipino and Christian-Benedictine values, academic excellence,
                                        public service and social responsibility, the program aims to produce future media
                                        and communication practitioners committed to the advocacy of gender fairness,
                                        ecological harmony, social justice, and peace.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff;">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Political Science with Specialization in International Relations</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Bachelor of Arts in Political Science with Specialization in
                                        International Relations program explores the exercise of power in
                                        its various forms and contexts with emphasis on the practices
                                        in the Philippines, a country caught in the rapid process of social
                                        and political change. The Program provides students with a conceptual,
                                        theoretical, and practical study of politics that is both challenging
                                        and responsive to the times to prepare them for their chosen career in
                                        government, and foreign service, social development, public information,
                                        education and research, as well as for advanced studies in the
                                        social sciences and law.

                                        <br>
                                        <br>
                                        <b>Curriculum:</b>
                                        <br>
                                        <i>
                                            Political Scientists study governments, political processes, and political behavior at all levels.
                                            <br>
                                            The subfields of Political Science include Philippine Government and Politics,
                                            Political Dynamics, Comparative Politics, International Relations, and Political Theory and Methodology.
                                            These are all included in the curriculum with a particular focus on international relations.
                                        </i>

                                        <br>
                                        <br>
                                        <b>Carrer Opportunities:</b>
                                        <br>
                                        <ul class="text-left" style="padding-left: 30px;">
                                            <li>Academic</li>
                                            <li>Government Work</li>
                                            <li>Lawyer</li>
                                            <li>Political Economist</li>
                                            <li>Political Journalist</li>
                                            <li>Political Consultant</li>
                                            <li>Press and Public Relations Specialist</li>
                                            <li>Diplomat or Foreign Policy Expert</li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Environmental Studies</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        A four-year multidisciplinary undergraduate program that focuses on the study of co-existence and
                                        interdependence between human being and the environment. It equips students with the essential
                                        knowledge, skills and values to investigate various environmental challenges of today’s globalizing
                                        world and to understand how different spiritual, political, socio-economic, and cultural processes
                                        shape the interaction between human and the environment in a global and local context.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Fine Arts Major in Studio Arts</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        St. Scholastica’s College Manila offers Bachelor of Fine Arts programs that lay the foundation for knowledge
                                        and competencies in the effective and affective visuals-based translation of concepts.
                                        Learners can choose between two disciplines to specialize in,
                                        whether Visual Communication or Studio Arts. Welcoming applicants of any gender,
                                        both programs mold students into critical and creative thinkers within the college’s philosophy and values framework.
                                        The <b style="font-size: 1.10rem;">BFA Studio Arts</b> program continues to produce well-rounded visual artists who contribute to the arts and culture scene.
                                        They are introduced to the different art forms such as painting, sculpture, installation art, and
                                        digital art, among others, including the management of each. Training in the use of traditional and
                                        non-traditional materials and methods is given, and innovativeness is instilled.
                                        The students’ preparation culminates in the creation of a body of work that is presented in an exhibition.
                                        Aside from becoming fine artists, graduates are envisioned to be animators, art directors,
                                        consultants, or critics, curators, graphic artists or designers, muralists, production scene artists, and the like.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Fine Arts Major in Visual Communcation</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The <b style="font-size: 1.10rem;">BFA Visual Communication</b> majors embrace liberal arts education with studio-based courses
                                        beginning with the fundamentals of Fine Arts.  They hone skills in drawing and rendering in various media,
                                        and in articulating ideas to produce designs, communication materials, and campaigns that aim to
                                        meet targets and appeal to the intended audience. Their preparation incorporates the management
                                        facet of the creative industry and concludes with a directed, research-based advertising campaign.
                                        Career opportunities for graduates include becoming advertising artists, animators, art directors,
                                        brand strategists and developers, copywriters, creative directors, film and video editors,
                                        graphic artists or designers, illustrators, product designers, production designers,
                                        video editors, visual merchandisers, and web designers, among others.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Nutrition and Diatetics</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="col-md-6">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Special Needs Education(BSNED)</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Bachelor of Special Needs Education program prepares teachers who instruct and
                                        manage students with additional needs in inclusive and segregated settings.
                                        Graduates of the program are equipped to teach the basic education curriculum and
                                        alternate curricula depending on the needs of their students. Those who will teach in the
                                        elementary level are trained across different subject areas. Those who will teach in the
                                        secondary level are prepared to provide educational support to students with additional needs
                                        in inclusive classrooms or educational services to those who may be enrolled in special education centers.
                                        <br>
                                        <br>
                                        A graduate of this degree program should be able to work in regular and special education schools
                                        as a teacher, provided that she passes the Licensure Examination for Teachers in her subject area of specialization
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Elementary Education(Generalist)</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        This program provides the framework for a career in teaching. Graduates of this degree
                                        have the training to pursue careers in elementary teaching, as well as ESL teaching.
                                        The program will focus on understanding children in their various stages of
                                        development and learning, integrating multidisciplinary content with field experience
                                        in diverse settings.  This program will help prepare the student teacher to enter
                                        the classroom with the knowledge, skills and competencies necessary to make a difference
                                        in the lives of students.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Biology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The Bachelor of  Science in Biology program is anchored on the three domains of science teaching and learning:<br>
                                        (1) acquisition, comprehension, and application of scientific knowledge both in the local and global context;<br>
                                        (2) demonstration of science processes and skills, and<br>
                                        (3) practice of scientific attitudes and values (habits of the mind).<br>
                                        It provides the learners with a repertoire of competencies required in their future careers,
                                        be it in medicine, tertiary education, research, or industry.
                                        <br>
                                        <br>
                                        The curriculum targets the development of scientific, technological, and environmental literacy
                                        needed for graduates to become participative and productive members of society,
                                        wise decision makers, and critical problem solvers within the social, economic, political,
                                        cultural, and ethical dimensions of life. To achieve all these, the curriculum is crafted and
                                        organized around situations and problems that stimulate the curiosity and captivate the interest of the
                                        learners, utilizes varied teaching-learning approaches such as inquiry, STS and multi-strategy,
                                        and caters to performance and product-based assessments. Thus, all BS Biology majors, as early as theirfirst year,
                                        design and perform experimental researches, organize research forums and research colloquiums in addition to their class outputs.
                                        During their culminating year, they hold a thesis forum, mount a poster display of their theses,
                                        and produce experimental researches.
                                        <br>
                                        <br>
                                        A graduate of the BS Biology program may be engaged in research, teaching, non-research-based work
                                        such as testing and inspection, biology-based industries like production, food science and technology,
                                        management and marketing, as well as in communication and advocacy-related work involving science,
                                        technology and the environment.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Psychology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        This program is designed to provide students with the knowledge and skills
                                        related to psychological concepts and principles to prepare them towards a
                                        scientific understanding of human behavior. These skills are deemed
                                        relevant and useful in a variety of employment settings and careers.
                                        <br>
                                        <br>
                                        The program has two (2) tracks: the Clinical Psychology track and Industrial/Organizational Psychology track.
                                        Both tracks prepare students to become licensed psychometricians and to pursue
                                        diverse career opportunities that involve interpersonal relations, ethics and research,
                                        mental health and psychological welfare, development and functioning of the person, group,
                                        community, organizations and institutions. The rigorous BSP track is likewise a pre-medicine course.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Interior Design</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The <b style="font-size: 1.10rem;">Bachelor of Science in Interior Design</b> program blends liberal arts education with studio skills
                                        development and specialized courses aimed at building design competencies.Aspirants to the profession are
                                        trained in delivering design solutions that respond to functional, aesthetic,
                                        and codal requirements of residential and commercial interior design projects integrating cultural and environmental considerations,
                                        and which are communicated through industry-standard design documents.
                                        Learners synthesize acquired knowledge and skills in a research-based interior design proposal
                                        at the senior level and are prepared for the professional licensure examination. Graduates can enter
                                        professional practice and specialize in the different project types - residential, hospitality,
                                        retail, corporate, educational, healthcare, and others. They also enter allied fields such as
                                        furniture and accessories design, visual merchandising, production design, lighting design,
                                        events planning, styling and management, floral design and interior plantscaping, project management,
                                        and cultural heritage conservation.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Early Childhood Education(BECED)</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div>
                                        The BECEd is a four-year program. Specifically this program provides students with
                                        fundamental understanding and application of the principles of Early Childhood care and
                                        education, as well as experience in the application of these principles.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>

                    <div class="row" v-if="!isWindowOnDesktop">
                        <div class="col-md-6">
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Communication</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        The Bachelor of Arts in Communication program offers students
                                        the opportunity to develop their media and communication skills required
                                        for careers in radio, television, film, journalism, advertising, public
                                        relations, corporate communications, advocacy and development
                                        communication, new media, and other allied fields. It allows
                                        students to explore any of the following areas as a minor track:
                                        Advocacy Filmmaking, Communication for Development,
                                        Integrated Marketing Communications, and Multimedia Journalism.
                                        <br>
                                        <br>
                                        Anchored on Filipino and Christian-Benedictine values, academic excellence,
                                        public service and social responsibility, the program aims to produce future media
                                        and communication practitioners committed to the advocacy of gender fairness,
                                        ecological harmony, social justice, and peace.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="2" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Special Needs Education(BSNED)</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        The Bachelor of Special Needs Education program prepares teachers who instruct and
                                        manage students with additional needs in inclusive and segregated settings.
                                        Graduates of the program are equipped to teach the basic education curriculum and
                                        alternate curricula depending on the needs of their students. Those who will teach in the
                                        elementary level are trained across different subject areas. Those who will teach in the
                                        secondary level are prepared to provide educational support to students with additional needs
                                        in inclusive classrooms or educational services to those who may be enrolled in special education centers.
                                        <br>
                                        <br>
                                        A graduate of this degree program should be able to work in regular and special education schools
                                        as a teacher, provided that she passes the Licensure Examination for Teachers in her subject area of specialization
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff;">
                                    <template slot="title">
                                        <strong style="font-family: Arial; font-size: 0.6rem;">&nbsp;&nbsp;&nbsp;Bachelor of Arts in Pol. Sci. with specialization in International Relations</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        The Bachelor of Arts in Political Science with Specialization in
                                        International Relations program explores the exercise of power in
                                        its various forms and contexts with emphasis on the practices
                                        in the Philippines, a country caught in the rapid process of social
                                        and political change. The Program provides students with a conceptual,
                                        theoretical, and practical study of politics that is both challenging
                                        and responsive to the times to prepare them for their chosen career in
                                        government, and foreign service, social development, public information,
                                        education and research, as well as for advanced studies in the
                                        social sciences and law.

                                        <br>
                                        <br>
                                        <b>Curriculum:</b>
                                        <br>
                                        <i>
                                            Political Scientists study governments, political processes, and political behavior at all levels.
                                            <br>
                                            The subfields of Political Science include Philippine Government and Politics,
                                            Political Dynamics, Comparative Politics, International Relations, and Political Theory and Methodology.
                                            These are all included in the curriculum with a particular focus on international relations.
                                        </i>

                                        <br>
                                        <br>
                                        <b>Carrer Opportunities:</b>
                                        <br>
                                        <ul class="text-left" style="padding-left: 30px;">
                                            <li>Academic</li>
                                            <li>Government Work</li>
                                            <li>Lawyer</li>
                                            <li>Political Economist</li>
                                            <li>Political Journalist</li>
                                            <li>Political Consultant</li>
                                            <li>Press and Public Relations Specialist</li>
                                            <li>Diplomat or Foreign Policy Expert</li>
                                        </ul>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="4" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Elementary Education(Generalist)</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        This program provides the framework for a career in teaching. Graduates of this degree
                                        have the training to pursue careers in elementary teaching, as well as ESL teaching.
                                        The program will focus on understanding children in their various stages of
                                        development and learning, integrating multidisciplinary content with field experience
                                        in diverse settings.  This program will help prepare the student teacher to enter
                                        the classroom with the knowledge, skills and competencies necessary to make a difference
                                        in the lives of students.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Environmental Studies</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        A four-year multidisciplinary undergraduate program that focuses on the study of co-existence and
                                        interdependence between human being and the environment. It equips students with the essential
                                        knowledge, skills and values to investigate various environmental challenges of today’s globalizing
                                        world and to understand how different spiritual, political, socio-economic, and cultural processes
                                        shape the interaction between human and the environment in a global and local context.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="6" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Biology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        The Bachelor of  Science in Biology program is anchored on the three domains of science teaching and learning:<br>
                                        (1) acquisition, comprehension, and application of scientific knowledge both in the local and global context;<br>
                                        (2) demonstration of science processes and skills, and<br>
                                        (3) practice of scientific attitudes and values (habits of the mind).<br>
                                        It provides the learners with a repertoire of competencies required in their future careers,
                                        be it in medicine, tertiary education, research, or industry.
                                        <br>
                                        <br>
                                        The curriculum targets the development of scientific, technological, and environmental literacy
                                        needed for graduates to become participative and productive members of society,
                                        wise decision makers, and critical problem solvers within the social, economic, political,
                                        cultural, and ethical dimensions of life. To achieve all these, the curriculum is crafted and
                                        organized around situations and problems that stimulate the curiosity and captivate the interest of the
                                        learners, utilizes varied teaching-learning approaches such as inquiry, STS and multi-strategy,
                                        and caters to performance and product-based assessments. Thus, all BS Biology majors, as early as theirfirst year,
                                        design and perform experimental researches, organize research forums and research colloquiums in addition to their class outputs.
                                        During their culminating year, they hold a thesis forum, mount a poster display of their theses,
                                        and produce experimental researches.
                                        <br>
                                        <br>
                                        A graduate of the BS Biology program may be engaged in research, teaching, non-research-based work
                                        such as testing and inspection, biology-based industries like production, food science and technology,
                                        management and marketing, as well as in communication and advocacy-related work involving science,
                                        technology and the environment.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Fine Arts Major in Studio Arts</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        St. Scholastica’s College Manila offers Bachelor of Fine Arts programs that lay the foundation for knowledge
                                        and competencies in the effective and affective visuals-based translation of concepts.
                                        Learners can choose between two disciplines to specialize in,
                                        whether Visual Communication or Studio Arts. Welcoming applicants of any gender,
                                        both programs mold students into critical and creative thinkers within the college’s philosophy and values framework.
                                        The <b style="font-size: 1.10rem;">BFA Studio Arts</b> program continues to produce well-rounded visual artists who contribute to the arts and culture scene.
                                        They are introduced to the different art forms such as painting, sculpture, installation art, and
                                        digital art, among others, including the management of each. Training in the use of traditional and
                                        non-traditional materials and methods is given, and innovativeness is instilled.
                                        The students’ preparation culminates in the creation of a body of work that is presented in an exhibition.
                                        Aside from becoming fine artists, graduates are envisioned to be animators, art directors,
                                        consultants, or critics, curators, graphic artists or designers, muralists, production scene artists, and the like.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Psychology</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        This program is designed to provide students with the knowledge and skills
                                        related to psychological concepts and principles to prepare them towards a
                                        scientific understanding of human behavior. These skills are deemed
                                        relevant and useful in a variety of employment settings and careers.
                                        <br>
                                        <br>
                                        The program has two (2) tracks: the Clinical Psychology track and Industrial/Organizational Psychology track.
                                        Both tracks prepare students to become licensed psychometricians and to pursue
                                        diverse career opportunities that involve interpersonal relations, ethics and research,
                                        mental health and psychological welfare, development and functioning of the person, group,
                                        community, organizations and institutions. The rigorous BSP track is likewise a pre-medicine course.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Fine Arts Major in Visual Communcation</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        The <b style="font-size: 1.10rem;">BFA Visual Communication</b> majors embrace liberal arts education with studio-based courses
                                        beginning with the fundamentals of Fine Arts.  They hone skills in drawing and rendering in various media,
                                        and in articulating ideas to produce designs, communication materials, and campaigns that aim to
                                        meet targets and appeal to the intended audience. Their preparation incorporates the management
                                        facet of the creative industry and concludes with a directed, research-based advertising campaign.
                                        Career opportunities for graduates include becoming advertising artists, animators, art directors,
                                        brand strategists and developers, copywriters, creative directors, film and video editors,
                                        graphic artists or designers, illustrators, product designers, production designers,
                                        video editors, visual merchandisers, and web designers, among others.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Interior Design</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        The <b style="font-size: 1.10rem;">Bachelor of Science in Interior Design</b> program blends liberal arts education with studio skills
                                        development and specialized courses aimed at building design competencies.Aspirants to the profession are
                                        trained in delivering design solutions that respond to functional, aesthetic,
                                        and codal requirements of residential and commercial interior design projects integrating cultural and environmental considerations,
                                        and which are communicated through industry-standard design documents.
                                        Learners synthesize acquired knowledge and skills in a research-based interior design proposal
                                        at the senior level and are prepared for the professional licensure examination. Graduates can enter
                                        professional practice and specialize in the different project types - residential, hospitality,
                                        retail, corporate, educational, healthcare, and others. They also enter allied fields such as
                                        furniture and accessories design, visual merchandising, production design, lighting design,
                                        events planning, styling and management, floral design and interior plantscaping, project management,
                                        and cultural heritage conservation.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Science in Nutrition and Diatetics</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                        do eiusmod tempor incididunt ut labore et dolore magna
                                        aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                        Duis aute irure dolor in reprehenderit in voluptate velit esse
                                        cillum dolore eu fugiat nulla pariatur. Excepteur sint
                                        occaecat cupidatat non proident, sunt in culpa qui officia
                                        deserunt mollit anim id est laborum.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                            <div style="margin-bottom: 15px;"></div>
                            <el-collapse>
                                <el-collapse-item name="8" style="background-color: #012376; text-color: #ffffff">
                                    <template slot="title">
                                        <strong style="font-family: Arial;">&nbsp;&nbsp;&nbsp;Bachelor of Early Childhood Education(BECED)</strong>
                                    </template>
                                    <div>&nbsp;</div>
                                    <div style="width: 50%;">
                                        The BECEd is a four-year program. Specifically this program provides students with
                                        fundamental understanding and application of the principles of Early Childhood care and
                                        education, as well as experience in the application of these principles.
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" style="margin-top: 150px; margin-bottom: 150px;">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="text-left justify-content-start ssa-text-color" style="font-family: Arial; font-size: 5rem; margin-bottom: 25px;">CONTACT US:</h1>
                        <h4 class="text-left justify-content-start ssa-text-color" style="font-size: 2rem;">
                            <strong>School of Arts and Sciences</strong><br>
                            local 2292 (Dean of School of Arts and Sciences Secretary)
                        </h4>
                    </div>
                </div>
            </div>
        </div>
        <!--CONTENTS END-->

        <!--FOOTER START-->
        <div class="footer-banner-container">
            <img :src="env_wp.APP_URL+'/public/assets/img/ssa_manila/pre_school_kinder/footer_banner2.png'">
        </div>
<!--        <div style="background: #012376;">-->
<!--            <div class="row">-->
<!--                &nbsp;-->
<!--            </div>-->
<!--        </div>-->
        <SiteFooter :style="{backgroundImage: `url('${env_wp.APP_URL}/public/assets/img/ssa_manila/hompage/footer_assets/comp/footer_backdrop.png')`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', position: 'relative', width: '100%'}"></SiteFooter>
        <!--FOOTER END-->
    </div>
</template>
<style>
    .ssa-card{
        background-color: #012376;
        height: 150px;
    }

    .el-carousel__item h3 {
        display: flex;
        color: #475669;
        opacity: 5;
        line-height: 300px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #012376;
        border-radius: 20px;
        opacity: 75%;
    }

    .centered-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
        font-size: 16px;
        font-weight: bold;
        z-index: 1;
    }
    .btn-ssa{
        background-color: #012376;
    }

    .school-section {
        padding: 20px 0;
        background-color: #f8f9fa;
    }
    .school-card {
        margin-bottom: 20px;
    }
    .school-card img {
        width: 100%;
        height: auto;
    }
    .school-card .card-body {
        background-color: #002e6e;
        color: white;
    }

    .footer-banner-container {
        position: relative;
        width: 100%;
        padding-top: 36.52%; /* Aspect ratio (328 / 898 * 100%) */
        background-color: #002d72; /* Fallback background color */
    }

    .footer-banner-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid white;
        border-radius: 5px;
    }
    .logo{
        width: 200px;
        height: 200px;
        margin-right: 10px;
    }
    .el-collapse-item__content{
        width: 700px;
        margin-left: 15px;
    }
</style>
<script>
    import NavbarStatic from "../../../../components/general/NavbarStatic";
    import SiteHeader from "../../../../components/general/SiteHeader";
    import SiteFooter from "../../../../components/general/SiteFooter";
    export default {
        props: {
            resources: Array
        },
        components: { NavbarStatic,SiteHeader,SiteFooter },
        data () {
            return {
                folder_name: this.$root.folder_name,
                env_wp: this.$root.env_wp.env_wp,
                screenWidth: 0,
                isWindowOnDesktop: true,
            }
        },
        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize)
            });
            this.onResize();
        },
        created() {

        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        watch: {
            screenWidth(width) {
                if (width > 768) {
                    this.isWindowOnDesktop = true;
                    //this.css = 'mt-3 px-5';
                } else {
                    this.isWindowOnDesktop = false;
                    //this.css = 'mt-3 px-3';
                }
            },
        },
        methods: {
            scrollToTop(){
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            },
            onResize(event) {
                this.screenWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            },
        }
    }
</script>